export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LEGACY_CLIENT = "LEGACY_CLIENT";
export const LEGACY_FAIL = "LEGACY_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const SOCIAL_LOGIN_SUCCESS = "SOCIAL_LOGIN_SUCCESS";
export const SOCIAL_LOGIN_FAIL = "SOCIAL_LOGIN_FAIL";
export const LOGOUT_USER = "LOGOUT_USER";
export const USER_LOADED = "USER_LOADED";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const AUTH_ERROR = "AUTH_ERROR";

export const FETCH_INVOICES = "FETCH_INVOICES";
export const FETCH_INVOICE_BY_ID = "FETCH_INVOICE_BY_ID";
export const FETCH_INVOICE_BY_SHOOT_ID = "FETCH_INVOICE_BY_SHOOT_ID";
export const FETCH_CLIENT_INVOICES = "FETCH_CLIENT_INVOICES";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const CREATE_INVOICE = "CREATE_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const FETCH_INVOICES_START = "FETCH_INVOICES_START";
export const FETCH_PHOTOG_DIST_SUCCESS = "FETCH_PHOTOG_DIST_SUCCESS";

export const FETCH_OFFERINGS = "FETCH_OFFERINGS";
export const FETCH_OFFERINGS_TABLEVIEW = "FETCH_OFFERINGS_TABLEVIEW";
export const UPDATE_BOOKSHOOT_STATE = "shootState";
export const UPDATE_INVOICE_STATE = "invoiceState";
export const FETCH_CLIENTS = "FETCH_CLIENTS";
export const FETCH_CLIENTS_TABLEVIEW = "FETCH_CLIENTS_TABLEVIEW";
export const FETCH_CLIENT = "FETCH_CLIENT";
export const FETCH_CLIENT_SHOOTS = "FETCH_CLIENT_SHOOTS";
export const FETCH_CLIENT_BY_USER_ID = "FETCH_CLIENT_BY_USER_ID";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const CREATE_CLIENT = "CREATE_CLIENT";
export const CREATE_USER_CLIENT = "CREATE_USER_CLIENT";
export const FETCH_CLIENT_BY_NAME = "FETCH_CLIENT_BY_NAME";
export const FETCH_CLIENT_STATS_SUCCESS = "FETCH_CLIENT_STATS_SUCCESS";
export const FETCH_CLIENT_STATS_FAIL = "FETCH_CLIENT_STATS_FAIL";

export const FETCH_BROKERAGES = "FETCH_BROKERAGES";
export const FETCH_BROKERAGE = "FETCH_BROKERAGE";
export const FETCH_BROKERAGES_TABLEVIEW = "FETCH_BROKERAGES_TABLEVIEW";
export const UPDATE_BROKERAGE = "UPDATE_BROKERAGE";
export const CREATE_BROKERAGE = "CREATE_BROKERAGE";
export const REMOVE_BROKERAGE = "REMOVE_BROKERAGE";
export const FETCH_BROKERAGE_CLIENTS = "FETCH_BROKERAGE_CLIENTS";

export const FETCH_BLOG_POSTS = "FETCH_BLOG_POSTS";
export const FETCH_BLOG_POSTS_TABLEVIEW = "FETCH_BLOG_POSTS_TABLEVIEW";
export const FETCH_BLOG_POST = "FETCH_BLOG_POST";
export const UPDATE_BLOG_POST = "UPDATE_BLOG_POST";
export const CREATE_BLOG_POST = "CREATE_BLOG_POST";
export const REMOVE_BLOG_POST = "REMOVE_BLOG_POST";

export const FETCH_TEMPLATES = "FETCH_TEMPLATES";
export const FETCH_TEMPLATES_TABLEVIEW = "FETCH_TEMPLATES_TABLEVIEW";
export const FETCH_TEMPLATE = "FETCH_TEMPLATE";
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const REMOVE_TEMPLATE = "REMOVE_TEMPLATE";

export const FETCH_DEVICES = "FETCH_DEVICES";
export const FETCH_DEVICES_TABLEVIEW = "FETCH_DEVICES_TABLEVIEW";
export const FETCH_DEVICE = "FETCH_DEVICE";
export const UPDATE_DEVICE = "UPDATE_DEVICE";
export const CREATE_DEVICE = "CREATE_DEVICE";
export const REMOVE_DEVICE = "REMOVE_DEVICE";

export const CREATE_PHOTOGRAPHER_SUCCESS = "CREATE_PHOTOGRAPHER_SUCCESS";
export const CREATE_PHOTOGRAPHER_FAIL = "CREATE_PHOTOGRAPHER_FAIL";
export const UPDATE_PHOTOGRAPHER = "UPDATE_PHOTOGRAPHER";
export const UPDATE_PHOTOGRAPHER_FOR_WEEKTOTAL =
  "UPDATE_PHOTOGRAPHER_FOR_WEEKTOTAL";
export const SELECT_PHOTOGRAPHER = "SELECT_PHOTOGRAPHER";
export const REMOVE_PHOTOGRAPHER = "REMOVE_PHOTOGRAPHER";
export const FETCH_PHOTOGRAPHERS = "FETCH_PHOTOGRAPHERS";
export const FETCH_PHOTOG_SHOOTS_SUCCESS = "FETCH_PHOTOG_SHOOTS_SUCCESS";
export const FETCH_PHOTOG_SHOOTS_FAIL = "FETCH_PHOTOG_SHOOTS_FAIL";
export const CREATE_PHOTOGRAPHER = "CREATE_PHOTOGRAPHER";
export const FETCH_PHOTOGRAPHER_BY_ID = "FETCH_PHOTOGRAPHER_BY_ID";
export const FETCH_PHOTOGRAPHER_BY_USER_ID = "FETCH_PHOTOGRAPHER_BY_USER_ID";
export const FETCH_PHOTOGRAPHER_UNAVAILABILITY =
  "FETCH_PHOTOGRAPHER_UNAVAILABILITY";
export const FETCH_PHOTOGRAPHER_UNAVAILABILITY_FOR_DAY =
  "FETCH_PHOTOGRAPHER_UNAVAILABILITY_FOR_DAY";
export const FETCH_ALL_PHOTOGRAPHER_UNAVAILABILITY =
  "FETCH_ALL_PHOTOGRAPHER_UNAVAILABILITY";
export const FETCH_ALL_PHOTOGRAPHER_UNAVAILABILITY_FAILED =
  "FETCH_ALL_PHOTOGRAPHER_UNAVAILABILITY_FAILED";
export const FETCH_UPSELL_LEADERBOARD = "FETCH_UPSELL_LEADERBOARD";

// export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
// export const CREATE_TEAM_FAIL = "CREATE_TEAM_FAIL";
export const UPDATE_TEAM = "UPDATE_TEAM";
export const SELECT_TEAM = "SELECT_TEAM";
export const REMOVE_TEAM = "REMOVE_TEAM";
export const FETCH_TEAMS = "FETCH_TEAMS";
// export const FETCH_PHOTOG_SHOOTS_SUCCESS = "FETCH_PHOTOG_SHOOTS_SUCCESS";
// export const FETCH_PHOTOG_SHOOTS_FAIL = "FETCH_PHOTOG_SHOOTS_FAIL";
export const CREATE_TEAM = "CREATE_TEAM";
export const FETCH_TEAM_BY_ID = "FETCH_TEAM_BY_ID";
export const FETCH_TEAM_BY_CLIENT_ID = "FETCH_TEAM_BY_CLIENT_ID";
export const FETCH_TEAM_BY_OWNER_ID = "FETCH_TEAM_BY_OWNER_ID";
export const ADD_MEMBER_BY_TEAM_ID = "ADD_MEMBER_BY_TEAM_ID";

export const CREATE_OFFERING = "CREATE_OFFERING";
export const REMOVE_OFFERING = "REMOVE_OFFERING";
export const UPDATE_OFFERING = "UPDATE_OFFERING";

export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const REMOVE_PAYMENT = "REMOVE_PAYMENT";
export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const FETCH_PAYMENTS = "FETCH_PAYMENTS";
export const FETCH_PAYMENTS_BY_INVOICE_ID = "FETCH_PAYMENTS_BY_INVOICE_ID";

export const FETCH_PAYMENT_METHODS_SUCCESS = "FETCH_PAYMENT_METHODS_SUCCESS";
export const FETCH_PAYMENT_METHODS_FAIL = "FETCH_PAYMENT_METHODS_FAIL";
export const SAVE_PAYMENT_METHOD_SUCCESS = "SAVE_PAYMENT_METHOD_SUCCESS";
export const SAVE_PAYMENT_METHOD_FAIL = "SAVE_PAYMENT_METHOD_FAIL";
export const REMOVE_PAYMENT_METHOD_SUCCESS = "REMOVE_PAYMENT_METHOD_SUCCESS";
export const REMOVE_PAYMENT_METHOD_FAIL = "REMOVE_PAYMENT_METHOD_FAIL";

export const FETCH_SHOOTS = "FETCH_SHOOTS";
export const FETCH_SHOOTS_CALENDARVIEW = "FETCH_SHOOTS_CALENDARVIEW";
export const FETCH_SHOOT_BY_ID = "FETCH_SHOOT_BY_ID";
export const FETCH_SHOOT_BY_HSN = "FETCH_SHOOT_BY_HSN";
export const UPDATE_SHOOT = "UPDATE_SHOOT";
export const UPDATE_SHOOT_MEDIA = "UPDATE_SHOOT_MEDIA";
export const CREATE_SHOOT = "CREATE_SHOOT";
export const UPDATE_SHARE = "UPDATE_SHARE";
export const REMOVE_SHOOT = "REMOVE_SHOOT";
export const RESCHEDULE_SHOOT = "RESCHEDULE_SHOOT";
export const FETCH_CHANGE_REQUESTS = "FETCH_CHANGE_REQUESTS";
export const FETCH_PENDING_SHOOTS = "FETCH_PENDING_SHOOTS";
export const FETCH_SHOOTS_BY_DAY = "FETCH_SHOOTS_BY_DAY";
export const CHANGE_CALENDAR_VIEW_DATE = "CHANGE_CALENDAR_VIEW_DATE";
export const CHANGE_CALENDAR_VIEW_RANGE = "CHANGE_CALENDAR_VIEW_RANGE";
export const CHANGE_CALENDAR_VIEW = "CHANGE_CALENDAR_VIEW";
export const UPDATE_WEBSOCKET_SHOOT = "UPDATE_WEBSOCKET_SHOOT";

export const FETCH_ENTITY_NOTES = "FETCH_ENTITY_NOTES";
export const FETCH_ENTITY_NOTES_FAIL = "FETCH_ENTITY_NOTES_FAIL";
export const FETCH_CLIENT_NOTES = "FETCH_CLIENT_NOTES";
export const FETCH_CLIENT_NOTES_FAIL = "FETCH_CLIENT_NOTES_FAIL";
export const FETCH_ACCESS_NOTES = "FETCH_ACCESS_NOTES";
export const FETCH_ACCESS_NOTES_FAIL = "FETCH_ACCESS_NOTES_FAIL";
export const CREATE_NOTE_SUCCESS = "CREATE_NOTE_SUCCESS";
export const CREATE_NOTE_FAIL = "CREATE_NOTE_FAIL";
export const CREATE_DUPLICATE_NOTES_SUCCESS = "CREATE_DUPLICATE_NOTES_SUCCESS";
export const CREATE_DUPLICATE_NOTES_FAIL = "CREATE_DUPLICATE_NOTES_FAIL";
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_FAIL = "UPDATE_NOTE_FAIL";
export const REMOVE_NOTE = "REMOVE_NOTE";

export const SEND_APPOINTMENT_CONFIRMATION = "SEND_APPOINTMENT_CONFIRMATION";
export const SEND_CANCEL_SHOOT = "SEND_CANCEL_SHOOT";
export const SEND_POSTPONE_SHOOT = "SEND_POSTPONE_SHOOT";

export const FETCH_MEDIA_BY_ID = "FETCH_MEDIA_BY_ID";
export const UPDATE_MEDIA = "UPDATE_MEDIA";
export const CREATE_MEDIA = "CREATE_MEDIA";
export const REMOVE_MEDIA = "REMOVE_MEDIA";

export const FETCH_ALL_DOLLHOUSES = "FETCH_ALL_DOLLHOUSES";
export const FETCH_DOLLHOUSE_BY_SHOOT_ID = "FETCH_DOLLHOUSE_BY_SHOOT_ID";
export const UPDATE_DOLLHOUSE = "UPDATE_DOLLHOUSE";
export const CREATE_DOLLHOUSE = "CREATE_DOLLHOUSE";
export const REMOVE_DOLLHOUSE = "REMOVE_DOLLHOUSE";

export const FETCH_ALL_FLYERS = "FETCH_ALL_FLYERS";
export const FETCH_FLYER_BY_ID = "FETCH_FLYER_BY_ID";
export const UPDATE_FLYER = "UPDATE_FLYER";
export const CREATE_FLYER = "CREATE_FLYER";
export const REMOVE_FLYER = "REMOVE_FLYER";

export const FETCH_ACTIVITY_BY_ENTITY = "FETCH_ACTIVITY_BY_ENTITY";
export const CREATE_ACTIVITY = "CREATE_ACTIVITY";
export const REMOVE_ACTIVITY = "REMOVE_ACTIVITY";

export const FETCH_CREDITS_SUCCESS = "FETCH_CREDITS_SUCCESS";
export const FETCH_CREDITS_FAIL = "FETCH_CREDITS_FAIL";
export const FETCH_CREDIT_BY_ID_SUCCESS = "FETCH_CREDIT_BY_ID_SUCCESS";
export const FETCH_CREDIT_BY_ID_FAIL = "FETCH_CREDIT_BY_ID_FAIL";
export const FETCH_CLIENT_CREDITS_SUCCESS = "FETCH_CLIENT_CREDITS_SUCCESS";
export const FETCH_CLIENT_CREDITS_FAIL = "FETCH_CLIENT_CREDITS_FAIL";
export const UPDATE_CREDIT_SUCCESS = "UPDATE_CREDIT_SUCCESS";
export const UPDATE_CREDIT_FAIL = "UPDATE_CREDIT_FAIL";
export const CREATE_CREDIT_SUCCESS = "CREATE_CREDIT_SUCCESS";
export const CREATE_CREDIT_FAIL = "CREATE_CREDIT_FAIL";
export const REMOVE_CREDIT_SUCCESS = "REMOVE_CREDIT_SUCCESS";
export const REMOVE_CREDIT_FAIL = "REMOVE_CREDIT_FAIL";
export const FETCH_CREDITS_TABLEVIEW_SUCCESS =
  "FETCH_CREDITS_TABLEVIEW_SUCCESS";
export const FETCH_CREDITS_TABLEVIEW_FAIL = "FETCH_CREDITS_TABLEVIEW_FAIL";

export const FETCH_COUPONS_SUCCESS = "FETCH_COUPONS_SUCCESS";
export const FETCH_COUPONS_FAIL = "FETCH_COUPONS_FAIL";
export const VALIDATE_COUPON_SUCCESS = "VALIDATE_COUPON_SUCCESS";
export const VALIDATE_COUPON_FAIL = "VALIDATE_COUPON_FAIL";
export const FETCH_COUPON_BY_ID_SUCCESS = "FETCH_COUPON_BY_ID_SUCCESS";
export const FETCH_COUPON_BY_ID_FAIL = "FETCH_COUPON_BY_ID_FAIL";
export const FETCH_CLIENT_COUPONS_SUCCESS = "FETCH_CLIENT_COUPONS_SUCCESS";
export const FETCH_CLIENT_COUPONS_FAIL = "FETCH_CLIENT_COUPONS_FAIL";
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS";
export const UPDATE_COUPON_FAIL = "UPDATE_COUPON_FAIL";
export const UPDATE_COUPON_BY_CODE_SUCCESS = "UPDATE_COUPON_SUCCESS";
export const UPDATE_COUPON_BY_CODE_FAIL = "UPDATE_COUPON_FAIL";
export const CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS";
export const CREATE_COUPON_FAIL = "CREATE_COUPON_FAIL";
export const REMOVE_COUPON_SUCCESS = "REMOVE_COUPON_SUCCESS";
export const REMOVE_COUPON_FAIL = "REMOVE_COUPON_FAIL";
export const FETCH_COUPONS_TABLEVIEW_SUCCESS =
  "FETCH_COUPONS_TABLEVIEW_SUCCESS";
export const FETCH_COUPONS_TABLEVIEW_FAIL = "FETCH_COUPONS_TABLEVIEW_FAIL";

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const REMOVE_SHOOT_CALENDAR = "REMOVE_SHOOT_CALENDAR";
export const SET_SELECTED_SHOOT = "SET_SELECTED_SHOOT";

export const UPDATE_WEBSOCKET_NOTE = "UPDATE_WEBSOCKET_NOTE";

export const FETCH_SUBDIVISION = "FETCH_SUBDIVISION";
export const FETCH_SUBDIVISIONS_TABLEVIEW = "FETCH_SUBDIVISIONS_TABLEVIEW";
export const CREATE_SUBDIVISION = "CREATE_SUBDIVISION";
export const UPDATE_SUBDIVISION = "UPDATE_SUBDIVISION";
export const REMOVE_SUBDIVISION = "REMOVE_SUBDIVISION";
export const FETCH_AMENITIES_BY_SUBDIVISION_ID =
  "FETCH_AMENITIES_BY_SUBDIVISION_ID";
export const FETCH_SUBDIVISION_BY_ID = "FETCH_SUBDIVISION_BY_ID";
export const FETCH_AMENITIES_BY_ID = "FETCH_AMENITIES_BY_ID";
export const REMOVE_AMENITY = "REMOVE_AMENITY";
export const CREATE_AMENITY = "CREATE_AMENITY";
export const UPDATE_AMENITY = "UPDATE_AMENITY";
export const RESET_SELECTED_AMENITY = "RESET_SELECTED_AMENITY";
export const UPDATE_AMENITIES = "UPDATE_AMENITIES";

export const FETCH_CAMPAIGNS_SUCCESS = "FETCH_CAMPAIGNS_SUCCESS";
export const FETCH_CAMPAIGNS_FAIL = "FETCH_CAMPAIGNS_FAIL";
export const FETCH_CAMPAIGN_BY_ID_SUCCESS = "FETCH_CAMPAIGN_BY_ID_SUCCESS";
export const FETCH_CAMPAIGN_BY_ID_FAIL = "FETCH_CAMPAIGN_BY_ID_FAIL";
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_FAIL = "UPDATE_CAMPAIGN_FAIL";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_FAIL = "CREATE_CAMPAIGN_FAIL";
export const REMOVE_CAMPAIGN_SUCCESS = "REMOVE_CAMPAIGN_SUCCESS";
export const REMOVE_CAMPAIGN_FAIL = "REMOVE_CAMPAIGN_FAIL";
// export const FETCH_CAMPAIGNS_TABLEVIEW_SUCCESS =
//   "FETCH_CAMPAIGNS_TABLEVIEW_SUCCESS";
// export const FETCH_CAMPAIGNS_TABLEVIEW_FAIL = "FETCH_CAMPAIGNS_TABLEVIEW_FAIL";
