import { combineReducers } from "redux";
import bookShootReducer from "./bookShootReducer";
import offeringReducer from "./offeringReducer";
import invoiceReducer from "./invoiceReducer";
import subdivisionReducer from "./subdivisionReducer";
import amenitiesReducer from "./amenitiesReducer";
import creditReducer from "./creditReducer";
import shootReducer from "./shootReducer";
import photographerReducer from "./photographerReducer";
import brokerageReducer from "./brokerageReducer";
import clientReducer from "./clientReducer";
import note from "./note";
import activityReducer from "./activityReducer";
import paymentReducer from "./paymentReducer";
import auth from "./authReducer";
import mediaReducer from "./mediaReducer";
import dollhouseReducer from "./dollhouseReducer";
import flyerReducer from "./flyerReducer";
import blogPostReducer from "./blogPostReducer";
import templateReducer from "./templateReducer";
import deviceReducer from "./deviceReducer";
import campaignReducer from "./campaignReducer";
import teamReducer from "./teamReducer";
import couponReducer from "./couponReducer";

import modal from "./modal";
import { LOGOUT_USER } from "../action/types";

const appReducer = combineReducers({
  shoot: bookShootReducer,
  offering: offeringReducer,
  invoice: invoiceReducer,
  credit: creditReducer,
  shoots: shootReducer,
  photographer: photographerReducer,
  media: mediaReducer,
  dollhouses: dollhouseReducer,
  brokerages: brokerageReducer,
  clients: clientReducer,
  payments: paymentReducer,
  flyers: flyerReducer,
  blogPosts: blogPostReducer,
  templates: templateReducer,
  devices: deviceReducer,
  note: note,
  activity: activityReducer,
  team: teamReducer,
  subdivision: subdivisionReducer,
  amenities: amenitiesReducer,
  coupon: couponReducer,
  campaign: campaignReducer,
  auth,
  modal,
});

export const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    state = undefined;
  }
  return appReducer(state, action);
};
