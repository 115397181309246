import {
  FETCH_CAMPAIGNS_SUCCESS,
  FETCH_CAMPAIGNS_FAIL,
  FETCH_CAMPAIGN_BY_ID_SUCCESS,
  FETCH_CAMPAIGN_BY_ID_FAIL,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAIL,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAIL,
  REMOVE_CAMPAIGN_SUCCESS,
  REMOVE_CAMPAIGN_FAIL,
  // FETCH_CAMPAIGNS_TABLEVIEW_SUCCESS,
  // FETCH_CAMPAIGNS_TABLEVIEW_FAIL,
} from "../action/types";

const initialState = {
  campaigns: [],
  selectedCampaign: {},
  isLoading: true,
  clientCampaigns: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.payload,
        isLoading: false,
      };
    // case FETCH_CAMPAIGNS_TABLEVIEW_SUCCESS:
    //   return {
    //     ...state,
    //     campaigns: action.payload,
    //     isLoading: false,
    //   };
    // case FETCH_CLIENT_CAMPAIGNS_SUCCESS:
    //   return {
    //     ...state,
    //     clientCampaigns: action.payload,
    //     isLoading: false,
    //   };

    case FETCH_CAMPAIGNS_FAIL ||
      CREATE_CAMPAIGN_FAIL ||
      UPDATE_CAMPAIGN_FAIL ||
      FETCH_CAMPAIGN_BY_ID_FAIL ||
      REMOVE_CAMPAIGN_FAIL:
      return { ...state, error: action.payload, isLoading: false };

    case FETCH_CAMPAIGN_BY_ID_SUCCESS:
      return {
        ...state,
        selectedCampaign: action.payload,
      };

    case CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: {
          campaigns: state.campaigns?.campaigns
            ? [action.payload, ...state.campaigns?.campaigns]
            : [],
          count: state.campaigns.count + 1,
        },
        clientCampaigns: state.clientCampaigns
          ? [action.payload, ...state.clientCampaigns]
          : [],
      };
    case REMOVE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: {
          campaigns: state.campaigns?.campaigns?.filter(
            (camp) => camp._id !== action.payload
          ),
          count: state.campaigns.count - 1,
        },
      };
    case UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          campaigns: state.campaigns?.campaigns?.map((camp) =>
            camp._id === action.payload._id ? action.payload : camp
          ),
        },
        clientCampaigns: state.clientCampaigns?.map((camp) =>
          camp._id === action.payload._id ? action.payload : camp
        ),
        selectedCampaign: action.payload,
      };

    default:
      return state;
  }
}
